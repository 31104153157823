import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,9];

export const dictionary = {
		"/": [~11],
		"/about-dubclub": [12],
		"/capper/dashboard": [13],
		"/capper/manage-prices": [~14,[2],[3]],
		"/capper/products": [~15],
		"/capper/profile/[name_or_price]": [~16],
		"/capper/referral": [17,[4]],
		"/capper/stream/[name]": [~18,[5]],
		"/footer": [19],
		"/plays/[textpickKey]": [~20],
		"/plays/[textpickKey]/[viewerKey]": [~21],
		"/product/new": [22],
		"/publish": [23,[6]],
		"/publish/play/[capperKey]": [~24,[6]],
		"/publish/sales": [~25,[6]],
		"/r/capper": [26],
		"/r/discover-cappers": [27,[7]],
		"/r/discover-cappers/(discovery)/cappers": [28,[7,8]],
		"/r/discover-cappers/(discovery)/leagues": [29,[7,8]],
		"/r/discover-cappers/(discovery)/plays": [30,[7,8]],
		"/r/discover-cappers/(discovery)/search": [31,[7,8]],
		"/r/discover-cappers/(discovery)/sports": [32,[7,8]],
		"/samples/[sampleKey]": [~33],
		"/samples/[sampleKey]/[viewerKey]": [~34],
		"/t/[productKey]": [35,[9]],
		"/t/[productKey]/edit": [36],
		"/t/[productKey]/faqs": [37,[9]],
		"/t/[productKey]/notifications": [38,[9]],
		"/viewm/[marketing=lookup_key]/[user=lookup_key]": [~39,[10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';